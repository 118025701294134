@mixin mobile {
  @media (max-width: 900px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 901px) and (max-width: 1199px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin lg_desktop {
  @media (min-width: 1800px) {
    @content;
  }
}

.newTop {
  position: relative;
  overflow: hidden;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  justify-content: center;
  margin-top: 40px;
  width: 100%;

  video {
    position: absolute;
    z-index: 0;
    object-fit: cover;
  }

  h1,
  p,
  .buttons_top {
    position: relative;
    z-index: 2; /* Set a higher z-index to position the content above the video */
  }
  h1 {
    color: white;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 2);
    margin-left: 1px;
    text-align: center;
  }
  p {
    text-align: center;
    font-family: "Lexend", sans-serif !important;
    font-weight: 300;
    font-size: 22px;
    width: 60%;
    margin-left: 15px;
    margin-right: 15px;
    color: rgb(208, 208, 208);
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  @include mobile {
    p {
      text-align: center !important;
      width: 100%;
      font-size: 16px;
    }
  }

  // Add other styling as needed...
}
@media (max-width: 770px) {
  .newTop video {
    height: 100%;
  }
}
@media (max-width: 430px) {
  .newTop {
    height: 300px;
  }
}

.buttons_top {
  display: flex;
  flex-direction: row;
  gap: 28px;
  @include mobile {
    gap: 14px;
  }
}
.button_top {
  cursor: pointer;
  width: auto;
  min-width: 150px;
  padding: 8px 14px;
  color: #fff;
  background: #9b83f9;
  border-color: #9b83f9;
  border-radius: 50px;
  &:hover {
    background: #8168e1 !important;
  }
  @include mobile {
    font-size: 13px;
    padding: 4px 8px;
  }
}
