@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@500;600;700&display=swap');

@mixin mobile {
  @media (max-width: 900px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 901px) and (max-width: 1199px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin lg_desktop {
  @media (min-width: 1800px) {
    @content;
  }
}

.hero {
  background-image: url("/assets/images/collectibles/bgmusic.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 868px;
  width: 100%;
  padding: 0 50px;
  margin-top: -160px;
  margin-bottom: 60px;

  position: relative;
  overflow: hidden;

  @include mobile {
    background-position: center;
    background-size: cover;
    height: 500px;
    padding: 20px;
  }

  .heroVideo {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .overlay {
    background: rgb(3, 1, 30);
    background: linear-gradient(0deg,
        rgba(3, 1, 30, 1) 0%,
        rgba(3, 1, 30, 0) 100%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .heading {
    position: relative;
    bottom: 0;
    width: 100%;
    margin-top: 30vh;

    @include mobile {
      width: 100%;
      margin-top: 25vh;
      padding-bottom: 20vh;
    }

    h3 {
      width: fit-content;
      font-weight: 700;
      font-size: 56px;
      line-height: 64px;
      letter-spacing: -0.03em;
      color: #fff0fa;
      text-align: left;

      @include mobile {
        font-size: 36px;
        line-height: 44px;
      }
    }

    p {
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      color: #ffffff;
      opacity: 0.9;

      @include mobile {
        font-size: 16px;
      }
    }
  }

  .button {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    width: 209px;
    height: 56px;
    color: #02001f;
    text-decoration: none;
    background-color: #59d6e6;
    border: 1px solid #59d6e6;
    border-radius: 12px;

    margin-top: 40px;
    transition: ease-in-out 0.25s;

    .icon {
      font-size: 28px;
    }

    &:hover {
      color: #02001f;
      background-color: #33919e;
    }

    @include mobile {
      width: 100%;
    }
  }
}