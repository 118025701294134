@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@500;600;700&display=swap");

@mixin mobile {
  @media (max-width: 900px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 901px) and (max-width: 1199px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin lg_desktop {
  @media (min-width: 1800px) {
    @content;
  }
}

.spotlight {
  position: relative;
  background-image: url("https://loop-markets.directus.app/assets/672b22f7-39d6-4389-b03c-c3103865f076");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-position-y: top;

  display: flex;
  justify-content: center;
  align-content: center;

  //height: 90vh;
  width: 100%;
  padding: 200px 50px 250px 50px;
  /*  margin-top: -130px;
  margin-bottom: 100px; */

  @include mobile {
    padding: 50px 20px;
  }

  .overlay {
    background: rgb(0, 0, 0);
    background: black;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .content {
    display: flex;
    align-items: center;
    position: relative;
    bottom: 0;
    width: 75%;
    margin: 0 auto;

    h3 {
      text-align: center;
      color: #fff;
      padding: 60px 0;
    }

    img {
      margin: auto;
      width: 100%;
    }

    @include mobile {
      width: 90%;
    }
  }
}
