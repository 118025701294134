@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@500;600;700&display=swap');
@import '/src/app/styles/_mixins.scss';

.cardLayout {
  //background-image: url("/assets/images/fansbg02.png");
  background-repeat: no-repeat;
  //background-position: center;
  background-size: 100% 100%;
  width: 100%;
  //height: 970px;
  padding: 100px 0;
  text-align: center;

  //margin: 100px 0;
  @include mobile {
    padding: 100px 10px;
  }

  @include tablet {
    padding: 100px 0;
  }

  b {
    color: #59d6e6;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.06em;

    span {
      background: -webkit-linear-gradient(358deg, var(--purple), var(--blue));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .subTitle {
    color: #fff;
    margin-top: -56px;
    margin-bottom: 56px;
  }

  h3 {
    padding: 20px;
    margin-bottom: 56px;
    font-weight: 500;
    font-size: 58px;
    line-height: 60px;
    text-align: left;
    letter-spacing: -0.02em;
    color: #fff0fa;

    span {
      background: -webkit-linear-gradient(358deg, var(--purple), var(--blue));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    @include tablet {
      width: 100%;
      padding: 0;
      font-size: 48px;
      line-height: 48px;
    }

    @include mobile {
      width: 100%;
      padding: 0;
      font-size: 42px;
      line-height: 48px;
    }
  }

  .featured_nft_image {
    width: 200px;
  }

  .collection {
    width: 100%;
    width: 532px;
    background-color: #000 !important;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0), 0 0 0 1px rgba(0, 0, 0, 0);
    border-radius: 24px;

    @include mobile {
      height: 519px;
      width: 100%;
    }

    .collection_details {
      text-align: center;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0px;

      padding: 20px;
      background: linear-gradient(0deg,
          rgba(0, 0, 0, 0.8) 20%,
          rgba(14, 14, 24, 0.6),
          70%,
          rgba(23, 23, 40, 0) 100%);
      border-radius: 24px !important;

      .project_image img {
        max-width: 60px;
        border-radius: 50px;
      }

      p {
        color: #fff;

        span {
          color: rgba(246, 245, 255, 0.6);
          font-weight: 400 !important;
          font-size: 14px !important;
          line-height: 17px !important;
          text-align: center !important;
        }
      }

      .nft_button {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #887cec;
      }
    }
  }

  .content {
    padding: 0 !important;
  }


  .cards {
    display: flex;
    flex-wrap: wrap;
    //justify-content: center;
    align-items: center;
    gap: 50px;

    margin-bottom: 200px;
    padding: 0 20px;

    @include mobile {
      gap: 0;
      padding: 0;
      justify-content: center;
    }

    .card {
      flex: 0 1 calc(30% - 20px);
      width: 100%;
      background: transparent;
      box-shadow: none;
      border-radius: 24px;
  
      @include tablet {
        flex: 0 1 calc(50% - 40px);
      }

      @include mobile {
        flex: 0 1 calc(100% - 20px);
      }
  
      .card_details {
        border-radius: 24px !important;
        position: relative;

        .card_badge {
          position: absolute;
          top: 10px;
          right: 10px;

          //min-width: 140px;
          padding: 5px 20px;
          border-radius: 12px;
          font-weight: 500;
          font-size: 18px;
          line-height: 18px;
          background: -webkit-linear-gradient(358deg, var(--purple), var(--blue));

          .badge_text {
            color: #fff;
          }
        }

        .card_badgeUpcoming {
          background: #03011ecc;
          .badge_text {
            color: #fff;
          }
      }
  
        .icon_container {
          width: 100%;
          margin: auto;
          overflow: hidden;
          transition: ease-in-out 0.3s;
  
          .image_container {
            width: 100%;
            //height: 555px;
            min-height: 200px;
            border-radius: 15px;
            margin: 20px auto;
            overflow: hidden;
            display: flex;
            transition: ease-in-out 0.3s;
    
            img {
              width: auto;
              margin: auto;
            }
          }
          .details {
            font-family: "Lexend" !important;
            p {
              text-transform: lowercase;
              text-align: center !important;
            }
          }
        }
  
        .details {
          padding: 15px 0;
          p {
            line-height: 35px;
          }
        }
  
        .image_container {
          width: 100%;
          height: auto;
          border-radius: 15px;
          margin: 20px auto;
          overflow: hidden;
          display: flex;
          transition: ease-in-out 0.3s;
  
          img {
            width: 100%;
            object-fit: contain;
            transition: ease-in-out 0.3s;
          }
        }
  
        p {
          color: rgba(246, 245, 255, 0.6);
          font-size: 18px !important;
          text-align: left;
  
          padding: 0 10px;
  
          b {
            font-size: 18px !important;
            color: #fff;
            font-weight: bold;
            text-transform: capitalize;
          }
        }
  
        .nft_button {
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          text-align: center;
          color: #887cec;
        }
      }
    }
  }
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  min-width: 300px !important;
  color: #fff;
  text-decoration: none;
  border-radius: 12px;
  border-radius: 16px;
  border: none;
  background: linear-gradient(167deg, #5A46D7 10.44%, var(--purple) 105.52%);

  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;

  margin-top: 40px;
  padding: 20px 48px;
  transition: ease-in-out 200ms;

  .icon {
    font-size: 28px;
  }

  &:hover {
    transition: ease-in-out 200ms;
    background: linear-gradient(167deg, #5A46D7 10.44%, #5A46D7 105.52%);
  }

  @include mobile {
    width: 100%;
  }
}