@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@500;600;700&display=swap');

@mixin mobile {
  @media (max-width: 900px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 901px) and (max-width: 1199px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin lg_desktop {
  @media (min-width: 1800px) {
    @content;
  }
}

.column {
  margin: auto;
}

.hero_card {
  background-position: center !important;
  width: 90%;
  height: auto;
  margin: 200px auto;
  padding: 100px 0 !important;
  border-radius: 24px;

  @include mobile {
    width: 90% !important;
  }

  .content {
    padding: 70px;

    @include mobile {
      padding: 20px 10px;
    }
  }

  h3 {
    color: #FFF !important;
    font-size: 56px !important;
    font-weight: 700 !important;
    line-height: 60px !important;
    padding: 0 !important;

    @include mobile {
      font-size: 38px !important;
      line-height: 44px !important;
    }
  }

  p {
    color: #D2D4E1;
    font-size: 22px;
    font-weight: 400;
    line-height: 34px;
    padding: 24px 0;

    @include mobile {
      font-size: 18px;
      line-height: 24px;
    }
  }
}

.hero {
  background-image: url("/superFansBg5.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  //background-color: #090629;
  width: 100%;
  //height: 970px;
  padding: 200px 0;
  text-align: left;
  position: relative;

  .overlay {
    background: rgb(3, 1, 30);
    background: linear-gradient(180deg, rgba(2, 0, 31, 1) 0%, rgba(2, 0, 31, 0) 10%, rgba(2, 0, 31, 1) 100%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  //margin: 100px 0;
  @include mobile {
    background-position: 80%;
    padding: 100px 10px;

    .column {
      width: 100% !important;
    }
  }

  @include tablet {
    padding: 20px 0;

    .column {
      width: 50% !important;
    }
  }

  .smallHeadline {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.96px;
    text-transform: uppercase;
    color: #fff;
    opacity: 0.4;
  }

  h3 {
    margin-bottom: 26px;
    font-family: "Lexend";
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 56px;
    text-align: left;
    letter-spacing: -0.04em;
    color: #FFFFFF;


    span {
      background: -webkit-linear-gradient(358deg, var(--purple), var(--blue));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    @include mobile {
      padding: 0;
      font-size: 38px;
      line-height: 34px;
    }
  }

  .benefit {
    z-index: 2;
    background: transparent;
    box-shadow: none;
    width: 100%;
    border-radius: 24px;

    @include mobile {
      width: 100%;
      margin: 0 !important;
    }


    .benefits_details {
      display: flex;
      align-items: center;

      .icon {
        height: 40px;
        width: 40px;

        img {
          width: 100%;
          object-fit: contain;
        }
      }

      p {
        color: rgba(246, 245, 255, 0.6);
        font-size: 18px !important;
        text-align: left;

        padding: 20px 10px;
        font-family: 'Inter', sans-serif !important;

        b {
          font-family: 'Lexend', sans-serif !important;
          font-size: 18px !important;
          color: #fff;
          font-weight: 500;
        }

        span {
          font-family: 'Inter', sans-serif !important;
          color: #D2D4E1;
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
        }
      }
    }
  }

  .contentFlex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    div {
      flex: 50%;
    }
    @include mobile {
      flex-direction: column;
      .section {
        padding: 50px 0;
      }
    }
  }

  .content {
    position: relative;
    bottom: 0;
    width: 100%;
    .content {
      color: #D2D4E1;
      font-size: 60px;
      font-style: normal;
      font-weight: bold;
      line-height: 52px;
      letter-spacing: -1.2px;
    }
  }

  button {
    width: auto !important;
    height: 60px;
    margin: 24px 0;

    display: flex;
    padding: 20px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 16px;
    border: 1.5px solid rgba(255, 255, 255, 0.15);
    background: linear-gradient(167deg, #5A46D7 10.44%, #840CBD 105.52%);

    color: #fff;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    /* 133.333% */
  }

  .flex {
    display: flex;
    //align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    @include mobile {
      flex-direction: column-reverse;
    }

    .section {
      flex: 50%;
      padding: 50px 0;
    }

    img {
      width: 100%;
    }
  }
}