.ui .container .feed {
  @media #{$for-phone-only} {
    padding: 0!important;
  }
  .sixteen.wide.column{
    .ui.container{
      @media #{$for-phone-only} {
        padding: 0!important;
      }
    }
  }
  &-settings {
    height: 10vh;
  }
  .posts-list {}
}
@keyframes placeHolderShimmer{
  0%{
      background-position: -468px 0
  }
  100%{
      background-position: 468px 0
  }
}
.animated-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: var(--background-light);
  background: linear-gradient(to right, var(--loader-light) 8%, var(--background-light) 18%, var(--background-light) 33%);
  background-size: 800px 104px;
  height: 275px;
  position: relative;
  border-radius: 8px;
  margin-top: 20px
}

.ui.modal.ratingModalList{ 
  max-height: 85%;
  overflow: scroll;
  .header {
    background: unset !important;
    border-bottom: 1px solid rgba(255,255,255, 0.3);
  }

  .content{
    color: #fff;
  }


  .ratingRepScore{
    margin: 10px 0;
    .userAvatar {
      height: 40px;
      width: 40px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      border-radius: 100%;
      display: inline-block;
      vertical-align: top;
      margin-right: 15px;
    }
    .username{
      display: inline-block;
      width: 135px;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }
    .userDetails{
      display: inline-block;
      vertical-align: top;

      h6 {
        margin-bottom: 0;
      }

      span.pointsHightl {
        color: #98999b;
        background: #2a2b2d;
        padding: 2px 5px;
        display: inline-block;
        margin: -10 0 0 6px;
        border: 1px solid #6e6f71;
        border-radius: 1px;
        font-size: 12px;
        vertical-align: middle;
      }

      span.dots {
        background: #cccdcf;
        border-radius: 100%;
        width: 5px;
        height: 5px;
        display: inline-block;
        margin: 0 6px;
        vertical-align: middle;
      }

      p {
        font-weight: 400;
        color: #959698;
      }
    }
  }
}