@mixin mobile {
  @media (max-width: 900px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 901px) and (max-width: 1199px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin lg_desktop {
  @media (min-width: 1800px) {
    @content;
  }
}

.section {
  background-image: url("/assets/artist/48096387.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;
  padding: 120px 0;
  //margin-top: 100px;

  width: 100%;
  min-height: 600px;
  @include mobile {
    padding: 0;
    min-height: 400px;
    .break {
      display: none !important;
    }
  }
  .flares {
    position: absolute;
    bottom: -75px;
    right: 0;
    width: 750px;
    height: 370px;
    mix-blend-mode: screen;
    opacity: 0.7;
    @include mobile {
      right: -100px;
      bottom: 50px;
    }
  }
  .services {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 3fr 1fr 1fr;
    grid-template-areas: "left right-top right-bottom";
    margin: 0 50px;

    position: relative;
    .center {
      text-align: center;
    }
    .service_item {
      text-align: left;
      padding: 40px;
      border-radius: 16px;
      color: #FFFFFF;
      h2 {
        font-weight: 600;
        font-size: 72px;
        line-height: 90px;

        text-align: center;
        letter-spacing: -0.03em;

        color: #F6F5FF;
      }
      h3 {
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;
        color: #FFFFFF;
      }
      p {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: #999CB6;
        margin-top: 22px;
      }
      b {
        color: #fff;
      }
    }
    @include mobile {
      display: block;
      .service_item {
        margin-bottom: 40px;
        padding-bottom: 60px;
        border-radius: 16px;
      }
    }
    div {
      width: 100%;
    }
    .serviceImage {
      width: 56px !important;
      margin: auto;
    }
    .content {
      padding: 20px 0;
      h4 {
        font-weight: 600;
        font-size: 26px;
        line-height: 32px;
        font-feature-settings: "cv05" on, "cv11" on;
        color: #f6f5ff;
      }
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #999cb6;
      }
      @include mobile {
        text-align: center;
      }
    }
    a {
      color: #887dea;
      text-decoration: none;
      &:hover {
        color: #6359c1;
      }
    }
    button {
      min-width: 105px;
      height: 56px;
      padding: 10px 15px;
      color: #fff;
      background-color: #6959E6;
      box-shadow: 2px 2px 6px rgba(33, 57, 143, 0.4);
      border: 1px solid #887dea;
      border-radius: 44px;
      margin-top: 22px;
      transition: ease-in-out 0.25s;
      .icon {
        font-size: 28px;
      }
      &:hover {
        color: #fff;
        background-color: #887dea8f;
      }
      @include mobile {
        width: 100%;
      }
    }
  }

  .banner {
    background-image: url("/assets/images/marketbg_01.png") !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    border-radius: 24px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;

    text-align: left;

    padding: 50px !important;
    margin: 100px auto;
    @include mobile {
      height: auto;
      padding: 25px !important;
      margin: 50px auto;
    }
    .qrcode {
      width: 70px;
      border-radius: 14px;
      padding: 5px;
      background-color: #fff;
      @include mobile {
        display: none;
      }
    }
    .left {
      width: 30%;
      @include mobile {
        width: 100%;
      }
      @include tablet {
        width: 100%;
      }
    }
    .right {
      @include mobile {
        display: none;
      }
      @include tablet {
        display: none;
      }
    }
    .content {
      margin: 50px 0;
      h4 {
        font-weight: 700;
        font-size: 48px;
        line-height: 49px;
        letter-spacing: -0.03em;
        color: #ffffff;
        @include mobile {
          font-size: 32px;
          line-height: 30px;
        }
      }
      p {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: #ffffff;
        opacity: 0.9;
        @include mobile {
          font-size: 16px;
          line-height: 18px;
        }
      }
    }
    .buttons {
      display: flex !important;
      gap: 20px;
      @include mobile {
        display: block;
      }
      img {
        transition: ease-in-out 0.2s;
        @include mobile {
          width: 70%;
          margin: auto;
          padding: 10px 0;
        }
        &:hover {
          transform: scale(0.95);
        }
      }
    }
  }
}

.discover {
  background-image: url("/assets/images/fansbg02.png");
  background-repeat: no-repeat;
  //background-position: center;
  background-size: 100% 100%;
  width: 100%;
  //height: 970px;
  padding: 200px 50px 100px 50px;
  text-align: center;
  //margin: 100px 0;
  @include mobile {
    padding: 10px;
    .column {
      width: 100% !important;
    }
  }
  b {
    color: #59d6e6;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;
  }
  .container {
    max-width: 1440px !important;
    margin: auto;
  }

  h3 {
    padding: 20px;
    font-family: "Lexend";
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 56px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #fff0fa;
    span {
      background: -webkit-linear-gradient(358deg, #e659d6, #8877ea, #86e2ee);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    @include mobile {
      padding: 0;
      font-size: 28px;
      line-height: 24px;
    }
  }
  .featured_nft_image {
    width: 200px;
  }
  .swiper-container {
    padding-top: 55px;
    padding-bottom: 55px;
  }
  .swiper-pagination-bullet {
    width: 18px;
    height: 18px;
    display: inline-block;
    border-radius: 50%;
    background: rgba(136, 124, 235, 0.15);
    border: 2px solid #887ceb;
    opacity: 0.2;
  }
  .swiper-pagination-bullet-active {
    opacity: 1;
    background: #887ceb;
    border: 1px solid #887ceb;
  }
  .swiper-slide {
    border: 1px solid rgba(255, 255, 255, 0.12) !important;
    border-radius: 24px;
    transform: scale(0.8);
    transition: ease-in-out 0.5s;
  }
  .swiper-slide-active {
    border: 1px solid rgba(255, 255, 255, 0.12) !important;
    transform: scale(1);
    transition: ease-in-out 0.5s;
  }
  .swiper-button-next,
  .swiper-button-prev {
    color: #fff !important;
    background-image: none !important;
    width: 0 !important;
    height: 50px !important;
    &::after {
      padding: 15px;
      font-size: 25px;
      font-weight: 800;
      //background: rgba(255, 255, 255, 0.1);
      //border: 1px solid rgba(255, 255, 255, 0.08);
      border-radius: 40px;
    }
  }
  .swiper-container {
    background: transparent !important;
    border-color: transparent;
  }
  .collection {
    width: 100%;
    height: 519px;
    width: 532px;
    background-color: #000 !important;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0), 0 0 0 1px rgba(0, 0, 0, 0);
    border-radius: 24px;
    @include mobile {
      height: 519px;
      width: 100%;
    }
    .collection_details {
      text-align: center;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0px;

      padding: 20px;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.8) 20%,
        rgba(14, 14, 24, 0.6),
        70%,
        rgba(23, 23, 40, 0) 100%
      );
      border-radius: 24px !important;
      .project_image img {
        max-width: 60px;
        border-radius: 50px;
      }
      p {
        color: #fff;
        span {
          color: rgba(246, 245, 255, 0.6);
          font-weight: 400 !important;
          font-size: 14px !important;
          line-height: 17px !important;
          text-align: center !important;
        }
      }
      .nft_button {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #887cec;
      }
    }
  }
}



.benefits {
  background-image: url("/assets/artist/spotlight.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100%;
  //height: 800px;
  padding: 50px;
  text-align: center;
  //margin: 100px 0;
  @include mobile {
    padding: 10px;
    .column {
      width: 100% !important;
    }
  }
  @include tablet {
    padding: 20px 0;
    .column {
      width: 50% !important;
    }
  }
  b {
    color: #59d6e6;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;
  }
  .container {
    max-width: 1440px !important;
    margin: auto;
  }

  h3 {
    padding: 20px;
    margin-bottom: 56px;
    font-family: "Lexend";
    font-style: normal;
    font-weight: 600;
    font-size: 58px;
    line-height: 56px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #fff0fa;
    span {
      background: -webkit-linear-gradient(358deg, #e659d6, #8877ea, #86e2ee);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    @include mobile {
      padding: 0;
      font-size: 28px;
      line-height: 24px;
    }
  }
  .featured_nft_image {
    width: 200px;
  }
  .collection {
    width: 100%;
    width: 532px;
    background-color: #000 !important;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0), 0 0 0 1px rgba(0, 0, 0, 0);
    border-radius: 24px;
    @include mobile {
      height: 519px;
      width: 100%;
    }
    .collection_details {
      text-align: center;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0px;

      padding: 20px;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.8) 20%,
        rgba(14, 14, 24, 0.6),
        70%,
        rgba(23, 23, 40, 0) 100%
      );
      border-radius: 24px !important;
      .project_image img {
        max-width: 60px;
        border-radius: 50px;
      }
      p {
        color: #fff;
        span {
          color: rgba(246, 245, 255, 0.6);
          font-weight: 400 !important;
          font-size: 14px !important;
          line-height: 17px !important;
          text-align: center !important;
        }
      }
      .nft_button {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #887cec;
      }
    }
  }

  .content {
    padding: 0 !important;
  }

  .benefit {
    width: 100%;
    background: transparent;
    box-shadow: none;
    border-radius: 24px;
    @include mobile {
      width: 100%;
    }
    .benefits_details {
      border-radius: 15px !important;
      background: rgba(255, 255, 255, 0.04);
      margin: 10px 0;
      img {
        border-radius: 15px 15px 0 0 !important;
        width: 100%;
      }
      .video {
        border-radius: 15px 15px 0 0 !important;
        width: 100%;
      }
      p {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 16px !important;
        text-align: center;

        padding: 5px;
        b {
          font-size: 18px !important;
          color: #fff;
          font-weight: bold;
        }
      }
      .nft_button {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #887cec;
      }
      .video {
        width: 100%;
      }
      p {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 16px!important;
        text-align: center;
        padding: 15px;
      }
    }
  }
}


.featured_artist {
  background-image: url("/assets/images/fansbg02.png");
  background-repeat: no-repeat;
  //background-position: center;
  background-size: 100% 100%;
  width: 100%;
  //height: 970px;
  padding: 200px 50px 100px 50px;
  text-align: center;
  //margin: 100px 0;
  @include mobile {
    padding: 10px;
    .column {
      width: 100% !important;
    }
  }
  @include tablet {
    padding: 20px 0;
    .column {
      width: 50% !important;
    }
  }
  b {
    color: #59d6e6;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;
  }
  .container {
    max-width: 1440px !important;
    margin: auto;
  }

  h3 {
    padding: 20px;
    font-family: "Lexend";
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 56px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #fff0fa;
    &::before {
      content:url('/star_left.png');
      position:relative;
      left:-50px;
      top:10px;
    }
    &::after {
      content:url('/star_right.png');
      position:relative;
      right:-50px;
      top:10px;
    }
    span {
      background: -webkit-linear-gradient(358deg, #e659d6, #8877ea, #86e2ee);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    @include mobile {
      padding: 0;
      font-size: 28px;
      line-height: 24px;
    }
  }
  .featured_nft_image {
    width: 200px;
  }
  .artist {
    width: 100%;
    height: 380px;
    width: 532px;
    background-color: #000 !important;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0), 0 0 0 1px rgba(0, 0, 0, 0);
    border-radius: 24px;
    @include mobile {
      height: 519px;
      width: 100%;
    }
    .artist_details {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: left;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0px;

      padding: 20px;
      border-radius: 24px !important;
      .project_image img {
        max-width: 60px;
        border-radius: 50px;
      }
      p {
        font-size: 20px;
        color: #fff;
        span {
          display: block;
          color: rgba(246, 245, 255, 0.6);
          font-weight: 400 !important;
          font-size: 14px !important;
          line-height: 17px !important;
        }
      }
      .nft_button {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #887cec;
      }

      .social {
        display: inline-flex;
        gap: 10px;
        img {
          width: 40px;
        }
      }
    }
  }
  .artist_lg {
    width: 100%;
    height: 637px;
    background-color: #000 !important;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0), 0 0 0 1px rgba(0, 0, 0, 0);
    border-radius: 24px;
    @include mobile {
      height: 519px;
      width: 100%;
    }
    .artist_details {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0px;

      padding: 20px;
      border-radius: 24px !important;
      p {
        font-size: 28px;
        color: #fff;
        text-align: center;
        span {
          display: block;
          color: rgba(246, 245, 255, 0.6);
          font-weight: 400 !important;
          font-size: 14px !important;
          line-height: 17px !important;
        }
      }
      a {
        color: #887CEC;
        text-decoration: none;
      }
      .nft_button {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #887cec;
      }

      .social {
        display: inline-flex;
        gap: 10px;
        img {
          width: 40px;
        }
      }
    }
  }
}


.cta_card {
  width: 100%;
  height: 200px;

  border-radius: 10px;

  margin: auto;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @include mobile {
    background-image: none !important;
  }

  a {
    width: 300px;
    height: 100%;

    display: flex;
    align-items: center;
    margin: auto;

    .cta_button {
      width: 300px;
      height: 50px;

      background-color: #59d6e6;
      border: 1px solid #59d6e6;

      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;

      font-size: 24px;

      &:hover {
        cursor: pointer;
        background-color: #59d6e6;
      }
    }
  }
}

.countdown {

  text-align: center;
  margin: 100px 0;

  .button {
    width: 100% !important;
    min-width: 400px !important;
    max-width: 600px !important;
    height: auto !important;
    font-size: 32px;

  }
}