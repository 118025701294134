@import "../media.scss";

.signup {}

.column {
  margin-top: 100px !important;
  margin-bottom: 200px !important;
  max-width: 700px;

  @include mobile {
    margin-top: 10px !important;
    max-width: 100%;
    margin-left: 10px !important;
  }

  .grid {
    background: #100F1E;
    border: solid 1px #24243C !important;
    border-radius: 15px;
    padding-bottom: 25px !important;
    color: #fff;

    .header {
      display: block;
      width: 100%;
      padding: 25px;
      color: #fff;
      border-radius: 15px 15px 0 0;

      text-align: center;

      .title {
        font-weight: bolder;
        font-size: 22px;
      }

      .sub {
        font-size: 16px;
        color: rgba(246, 245, 255, 0.6);
      }
    }

    .form {
      width: 100%;
      .field {
        label {
          color: #fff !important;
        }
      }

      label {
        color: #fff !important;
      }

      input {
        background: rgba(255, 255, 255, 0.03);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 12px;
        color: #fff !important;

        padding: 15px;
        transition: ease-in-out 0.5s;

        &:focus {
          background: #161529;
          border: solid 2px #59d6e6;
          border-radius: 10px;
        }
      }

      .proceed {
        padding: 15px;
        border: solid 2px #59d6e6;
        background: #59d6e6;
      }
    }
  }
}