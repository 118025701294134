.addImage {
  position: relative;
  overflow: hidden;
  margin-right: .5rem;
  &:hover {
    cursor: pointer;
  }
}

.addImage>button {
  background: transparent;
  border: none;
  color: var(--green);
  padding: 0;
  font-size: 1.5em;
  &:hover {
    cursor: pointer;
  }
}

.addImage input[type=file] {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  font-size: 0;
  &:hover {
    cursor: pointer;
  }
}