@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@500;600;700&display=swap');

@mixin mobile {
  @media (max-width: 900px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 901px) and (max-width: 1199px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin lg_desktop {
  @media (min-width: 1800px) {
    @content;
  }
}

.purple {
  background: -webkit-linear-gradient(358deg, #947BF2, #947BF2) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
}

.bg2 {
  background-image: url("/assets/images/48095923.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.hero {
  background-image: url("https://loop-markets.directus.app/assets/4e57425e-1dbf-402c-8c79-9c9fd297be58");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-position-y: top;
  height: 90vh;
  width: 100%;
  padding: 0 50px;
  margin-top: -130px;
  margin-bottom: 60px;

  position: relative;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;

  @include mobile {
    background-position: center;
    background-size: cover;
    height: 800px;
    padding: 20px;
  }

  .heroVideo {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .overlay {
    background: rgb(3, 1, 30);
    background: linear-gradient(0deg,
        rgba(3, 1, 30, 1) 0%,
        rgba(3, 1, 30, 0.236) 20%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .heading {
    position: relative;
    bottom: 0;
    width: 100%;

    @include mobile {
      width: 100%;
      margin-top: 30vh;
      padding-bottom: 20vh;
    }

    h3 {
      width: fit-content;
      font-weight: 700;
      font-size: 56px;
      line-height: 44px;
      letter-spacing: -0.03em;
      color: #fff0fa;
      text-align: center;
      margin: auto;

      @include mobile {
        text-align: center;
        font-size: 28px;
        line-height: 44px;
        //margin: 0;
      }
    }

    h4 {
      font-weight: 300;
      text-align: center;
      line-height: 34px;
      @include mobile {
        text-align: center;
        font-size: 28px;
        line-height: 44px;
        padding: 0 !important;
      }
    }

    span {
      //display: block;
      background: -webkit-linear-gradient(358deg, var(--purple), var(--blue));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    p {
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      color: #ffffff;
      opacity: 0.9;

      @include mobile {
        font-size: 16px;
      }
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    @include mobile {
      display: block;
    }

    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      //margin: auto;
      min-width: 219px;
      width: auto;
      color: #fff;
      text-decoration: none;
      border-radius: 12px;
      border-radius: 16px;
      border: none;
      background: linear-gradient(167deg, #5A46D7 10.44%, var(--purple) 105.52%);
  
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
  
      margin-top: 40px;
      padding: 20px 48px;
      transition: ease-in-out 200ms;
  
      .icon {
        font-size: 28px;
      }
  
      &:hover {
        transition: ease-in-out 200ms;
        background: linear-gradient(167deg, #5A46D7 10.44%, #5A46D7 105.52%);
      }
  
      @include mobile {
        width: 100%;
      }
    }

    .button2 {
      display: flex;
      justify-content: center;
      align-items: center;
      //margin: auto;
      min-width: 219px;
      width: auto;
      color: #fff;
      text-decoration: none;
      border-radius: 12px;
      border-radius: 16px;
      border: none;
      background: linear-gradient(97deg, #62D9EC 0%, #9469E0 105.7%), linear-gradient(167deg, #5A46D7 10.44%, #840CBD 105.52%);
  
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
  
      margin-top: 40px;
      padding: 20px 48px;
      transition: ease-in-out 200ms;
  
      .icon {
        font-size: 28px;
      }
  
      &:hover {
        background: linear-gradient(97deg, #62D9EC 0%, #9469E0 0%)
      }
  
      @include mobile {
        width: 100%;
      }
    }
  }
}

.section {
  text-align: center;
  padding: 100px 0;

  //padding-bottom: 200px;
  .header {
    margin: 40px 0;
    h3 {
      color: #fff;
  
      font-weight: 600;
      font-size: 36px;
      line-height: 44px;
    }
  
    b {
      color: #59d6e6;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      /* identical to box height */
  
      text-align: center;
      letter-spacing: 0.06em;
      text-transform: uppercase;
    }
    span {
      //display: block;
      background: -webkit-linear-gradient(358deg, var(--purple), var(--blue)) !important;
      -webkit-background-clip: text !important;
      -webkit-text-fill-color: transparent !important;
    }

    p {
      font-weight: 300;
      font-size: 18px;
      line-height: 28px;
      color: #fff;
    }
  }

  &__one {
    background-image: url("../../../../staticAssets/images/home/background01.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    //height: 700px;
    padding: 0 0 100px 0;

    @include mobile {
      padding: 0 0 50px 0;
    }
  }

  &__cta {
    background-image: url("/assets/images/inloop02.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    padding: 200px 0;

    img {
      border: 1px solid rgba(255, 255, 255, 0.4);
      border-radius: 8px;
    }

    @include mobile {
      background-size: cover;

      .column {
        width: 100% !important;
        margin: auto;
        padding: 10px;

        .content {
          text-align: center;
          margin: auto;

          img {
            margin: auto;
            text-align: center;

            border: 1px solid rgba(255, 255, 255, 0.4);
            border-radius: 8px;
          }
        }
      }
    }

    @include mobile {
      padding: 50px 0;
    }

    .heading {
      height: 50rem;

      background-image: url("/assets/images/inloop01.svg");
      background-repeat: no-repeat;
      background-position: top;
      background-size: cover;
      padding: 100px 0;

      text-align: center;

      h3 {
        color: #fff;
        font-weight: 600;
        font-size: 48px;
        line-height: 56px;
        letter-spacing: -0.03em;

        margin-bottom: 20px;
      }

      p {
        width: 500px;

        color: #fff;
        font-weight: 300;
        font-size: 18px;
        line-height: 28px;
        letter-spacing: -0.03em;

        margin: auto;

        @include mobile {
          width: 100% !important;
          padding: 0 10px;
        }
      }
    }

    .content {
      margin-top: -100px !important;
      margin-bottom: -150px !important;

      .column {
        .content {
          margin-top: 0px !important;
          margin-bottom: 50px !important;
        }
      }
    }

    .footer {
      height: 50rem;
      background-image: url("/assets/images/inloop03.svg");
      background-repeat: no-repeat;
      background-position: bottom;
      background-size: cover;
      padding: 100px 0;

      position: relative;

      text-align: center;

      @include mobile {
        height: 25rem;
      }

      button {
        position: absolute;
        bottom: 12rem;
        left: 0;
        right: 0;

        width: 200px !important;
        padding: 22px 0px;
        margin: auto;

        color: #887dea;
        background: #02001f;
        border: 1.5px solid #887dea;
        border-radius: 12px;

        &:hover {
          color: #fff;
          background: #887dea;
        }

        @include mobile {
          bottom: 10vh;
        }
      }
    }
  }

  width: 100%;

  //padding: 0 50px 200px 50px;
  //padding-bottom: 100px;
  @include mobile {
    padding: 20px;

    .break {
      display: none !important;
    }
  }

  .services {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    //justify-content: space-between;
    gap: 20px;
    text-align: center;
    margin-top: 80px !important;

    //padding: 100px 0;
    .service_item {
      width: 322px;
      flex-basis: calc(33% - 20px);
      padding: 40px;
      background: rgba(255, 255, 255, 0.04);
      border-radius: 16px;
      b {
        color: #fff;
      }
    }

    @include mobile {
      display: block;
      width: 100%;

      .service_item {
        margin-bottom: 40px;
        padding-bottom: 60px;
        background: rgba(255, 255, 255, 0.04);
        border-radius: 16px;
        width: 100%;
      }
    }

    div {
      width: 100%;
    }

    .serviceImage {
      width: 56px !important;
      margin: auto;
    }

    .content {
      padding: 20px 0;

      h4 {
        font-weight: 600;
        font-size: 26px;
        line-height: 32px;
        font-feature-settings: "cv05"on, "cv11"on;
        color: #f6f5ff;
      }

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #999cb6;
      }

      @include mobile {
        text-align: center;
      }
    }

    a {
      color: #887dea;
      text-decoration: none;

      &:hover {
        color: #6359c1;
      }
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 209px;
      height: 56px;
      color: #887dea;
      background-color: transparent;
      border: 1px solid #887dea;
      border-radius: 12px;
      transition: ease-in-out 0.25s;

      .icon {
        font-size: 28px;
      }

      &:hover {
        color: #fff;
        background-color: #887dea8f;
      }

      @include mobile {
        width: 100%;
      }
    }
  }

  .banner {
    background-image: url("/assets/images/marketbg_01.png") !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    height: 500px;
    border-radius: 24px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;

    text-align: left;

    padding: 50px !important;
    margin: 100px auto;

    @include mobile {
      height: auto;
      padding: 25px !important;
      margin: 50px auto;
    }

    .qrcode {
      width: 70px;
      border-radius: 14px;
      padding: 5px;
      background-color: #fff;

      @include mobile {
        display: none;
      }
    }

    .left {
      width: 30%;

      @include mobile {
        width: 100%;
      }

      @include tablet {
        width: 100%;
      }
    }

    .right {
      @include mobile {
        display: none;
      }

      @include tablet {
        display: none;
      }
    }

    .content {
      margin: 50px 0;

      h4 {
        font-weight: 700;
        font-size: 48px;
        line-height: 49px;
        letter-spacing: -0.03em;
        color: #ffffff;

        @include mobile {
          font-size: 32px;
          line-height: 30px;
        }
      }

      p {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: #ffffff;
        opacity: 0.9;

        @include mobile {
          font-size: 16px;
          line-height: 18px;
        }
      }
    }

    .buttons {
      display: flex !important;
      gap: 20px;

      @include mobile {
        display: block;
      }

      img {
        transition: ease-in-out 0.2s;

        @include mobile {
          width: 70%;
          margin: auto;
          padding: 10px 0;
        }

        &:hover {
          transform: scale(0.95);
        }
      }
    }
  }
}

.discover {
  background-image: url("/assets/images/fansbg02.png");
  background-repeat: no-repeat;
  //background-position: center;
  background-size: 100% 100%;
  width: 100%;
  //height: 970px;
  padding: 100px 50px;
  text-align: center;

  //margin: 100px 0;
  .row {
    margin: auto;
  }

  @include mobile {
    padding: 10px;

    .column {
      width: 100% !important;
    }
  }

  @include tablet {
    .row {
      display: flex;
      justify-content: center;
    }
  }

  b {
    color: #59d6e6;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;
  }

  .container {
    max-width: 1440px !important;
    margin: auto;
  }

  .discoverContainer {
    max-width: 1190px !important;
    margin: auto;
  }

  h3 {
    padding: 20px;
    font-family: "Lexend";
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 56px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #fff0fa;

    span {
      background: -webkit-linear-gradient(358deg, var(--purple), var(--blue));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    @include mobile {
      padding: 0;
      font-size: 28px;
      line-height: 24px;
    }
  }

  .item {
    width: 225px;
    height: 295px;

    margin: auto;

    background: rgba(255, 255, 255, 0.04) !important;
    border-radius: 16px !important;
    padding: 0 !important;
    margin-top: 50px;

    @include mobile {
      width: 80%;
      height: 370px;
      margin-top: 20px !important;
    }

    .card {
      background-color: transparent !important;
      box-shadow: none;
      padding: 5px;

      @include mobile {
        margin: auto;
      }

      .image_container {
        margin: auto;
        width: 100%;
        height: 213px;
        background: rgba(255, 255, 255, 0.03);
        border-radius: 8px;

        @include mobile {
          width: 100%;
          height: 100%;
        }
      }
    }

    img {
      width: 100%;
    }

    p {
      text-align: center;
      margin-top: 10px;
      color: #999CB6;
      font-weight: 400;
      font-size: 12px;

      b {
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: #fff;
      }
    }
  }

  .item_lg {
    width: 100%;
    height: 100%;

    margin: auto;

    background: rgba(255, 255, 255, 0) !important;
    border-radius: 16px !important;
    padding: 0 !important;

    .image_container {
      margin: auto;
      width: 100%;
      height: 100%;
      border-radius: 8px;

      img {
        height: 100%;

        @include mobile {
          width: 100%;
          height: auto;
        }
      }

      @include mobile {
        margin-top: 50px;
      }
    }
  }

  .swiper-container {
    padding-top: 55px;
    padding-bottom: 55px;
  }

  .swiper-pagination-bullet {
    width: 18px;
    height: 18px;
    display: inline-block;
    border-radius: 50%;
    background: rgba(136, 124, 235, 0.15);
    border: 2px solid #887ceb;
    opacity: 0.2;
  }

  .swiper-pagination-bullet-active {
    opacity: 1;
    background: #887ceb;
    border: 1px solid #887ceb;
  }

  .swiper-slide {
    border: 1px solid rgba(255, 255, 255, 0.12) !important;
    border-radius: 24px;
    transform: scale(0.8);
    transition: ease-in-out 0.5s;
  }

  .swiper-slide-active {
    border: 1px solid rgba(255, 255, 255, 0.12) !important;
    transform: scale(1);
    transition: ease-in-out 0.5s;
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: #fff !important;
    background-image: none !important;
    width: 0 !important;
    height: 50px !important;

    &::after {
      padding: 15px;
      font-size: 25px;
      font-weight: 800;
      //background: rgba(255, 255, 255, 0.1);
      //border: 1px solid rgba(255, 255, 255, 0.08);
      border-radius: 40px;
    }
  }

  .swiper-container {
    background: transparent !important;
    border-color: transparent;
  }

  .collection {
    width: 100%;
    height: 519px;
    width: 532px;
    background-color: #000 !important;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0), 0 0 0 1px rgba(0, 0, 0, 0);
    border-radius: 24px;

    @include mobile {
      height: 519px;
      width: 100%;
    }

    .collection_details {
      text-align: center;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0px;

      padding: 20px;
      background: linear-gradient(0deg,
          rgba(0, 0, 0, 0.8) 20%,
          rgba(14, 14, 24, 0.6),
          70%,
          rgba(23, 23, 40, 0) 100%);
      border-radius: 24px !important;

      .project_image img {
        max-width: 60px;
        border-radius: 50px;
      }

      p {
        color: #fff;

        span {
          color: rgba(246, 245, 255, 0.6);
          font-weight: 400 !important;
          font-size: 14px !important;
          line-height: 17px !important;
          text-align: center !important;
        }
      }

      .nft_button {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #887cec;
      }
    }
  }
}

.learn {
  margin: 50px 0;
  @include mobile {
    padding: 10px;

    .column {
      width: 100% !important;
    }
  }

  @include tablet {
    padding: 20px 0;

    .column {
      width: 50% !important;
    }
  }

  b {
    color: #59d6e6;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;
  }

  .container {
    max-width: 1440px !important;
    margin: auto;
  }

  h3 {
    padding: 20px;
    margin-top: 56px;
    font-family: "Lexend";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    text-align: left;
    letter-spacing: -0.02em;
    color: #fff0fa;

    span {
      background: -webkit-linear-gradient(358deg, var(--purple), var(--blue));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    @include mobile {
      padding: 0;
      font-size: 28px;
      line-height: 24px;
    }
  }

  .featured_nft_image {
    width: 200px;
  }

  .benefit {
    width: 100%;
    background: transparent;
    box-shadow: none;
    border-radius: 24px;

    @include mobile {
      width: 100%;
    }

    .benefits_details {
      border-radius: 24px !important;
      padding: 10px;
      transition: ease-in-out 0.3s;

      &:hover {
        background-color: rgba(26, 26, 46, 1);
        cursor: pointer;
      }

      .image_container {
        width: 100%;
        height: 150px;

        background-color: #000;

        border-radius: 15px;
        overflow: hidden;
        display: flex;

        img {
          width: 100%;
          object-fit: cover;
          transition: ease-in-out 0.3s;
        }
      }

      p {
        color: #fff;
        font-size: 18px !important;
        text-align: left;

        padding: 0 10px;
        margin-top: 20px;

        span {
          font-weight: 400;
          font-size: 14px;
          color: #999CB6;
        }
      }

      .nft_button {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #887cec;
      }
    }
  }
}

.benefits {
  background-image: url("/assets/images/fansbg02.png");
  background-repeat: no-repeat;
  //background-position: center;
  background-size: 100% 100%;
  width: 100%;
  //height: 970px;
  padding: 200px 50px 100px 50px;
  text-align: center;

  //margin: 100px 0;
  @include mobile {
    padding: 10px;

    .column {
      width: 100% !important;
    }
  }

  @include tablet {
    padding: 20px 0;

    .column {
      width: 50% !important;
    }
  }

  b {
    color: #59d6e6;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;
  }

  .container {
    max-width: 1440px !important;
    margin: auto;
  }

  h3 {
    padding: 20px;
    margin-bottom: 56px;
    font-family: "Lexend";
    font-style: normal;
    font-weight: 600;
    font-size: 58px;
    line-height: 56px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #fff0fa;

    span {
      background: -webkit-linear-gradient(358deg, var(--purple), var(--blue));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    @include mobile {
      padding: 0;
      font-size: 28px;
      line-height: 24px;
    }
  }

  .featured_nft_image {
    width: 200px;
  }

  .collection {
    width: 100%;
    width: 532px;
    background-color: #000 !important;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0), 0 0 0 1px rgba(0, 0, 0, 0);
    border-radius: 24px;

    @include mobile {
      height: 519px;
      width: 100%;
    }

    .collection_details {
      text-align: center;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0px;

      padding: 20px;
      background: linear-gradient(0deg,
          rgba(0, 0, 0, 0.8) 20%,
          rgba(14, 14, 24, 0.6),
          70%,
          rgba(23, 23, 40, 0) 100%);
      border-radius: 24px !important;

      .project_image img {
        max-width: 60px;
        border-radius: 50px;
      }

      p {
        color: #fff;

        span {
          color: rgba(246, 245, 255, 0.6);
          font-weight: 400 !important;
          font-size: 14px !important;
          line-height: 17px !important;
          text-align: center !important;
        }
      }

      .nft_button {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #887cec;
      }
    }
  }

  .content {
    padding: 0 !important;
  }

  .benefit {
    width: 100%;
    background: transparent;
    box-shadow: none;
    border-radius: 24px;

    @include mobile {
      width: 100%;
    }

    .benefits_details {
      border-radius: 24px !important;

      &:hover {
        cursor: pointer;
        .image_container {
          background: #03011e;
          box-shadow: 0 1px 50px 0 rgb(113, 25, 181), 0 0 10px 1px rgba(0, 0, 0, 0);
          border-radius: 10%;

          img {
            transform: scale(1.4) rotate(-10deg);
          }
        }
      }

      .image_container {
        height: 350px;
        width: 366px;
        margin: 20px 0;
        overflow: hidden;
        display: flex;
        transition: ease-in-out 0.3s;

        img {
          width: 100%;
          object-fit: contain;
          transition: ease-in-out 0.3s;
        }
      }

      p {
        color: rgba(246, 245, 255, 0.6);
        font-size: 18px !important;
        text-align: left;

        padding: 0 10px;
        margin-top: 20px;

        b {
          font-size: 18px !important;
          color: #fff;
          font-weight: bold;
        }
      }

      .nft_button {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #887cec;
      }
    }
  }
}

.featured_artist {
  width: 100%;
  //height: 970px;
  padding: 100px 50px;
  text-align: center;

  //margin: 100px 0;
  @include mobile {
    padding: 10px;

    .column {
      width: 100% !important;
    }
  }

  @include tablet {
    padding: 20px 0;

    .column {
      width: 50% !important;
    }
  }

  b {
    color: #59d6e6;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;
  }

  .container {
    max-width: 1440px !important;
    margin: auto;
  }

  h3 {
    padding: 20px;
    font-family: "Lexend";
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 56px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #fff0fa;

    &::before {
      content: url("/star_left.png");
      position: relative;
      left: -50px;
      top: 10px;
      @include mobile {
        left: -25px;
      }
    }

    &::after {
      content: url("/star_right.png");
      position: relative;
      right: -50px;
      top: 10px;
      @include mobile {
        right: -25px;
      }
    }

    span {
      background: -webkit-linear-gradient(358deg, var(--purple), var(--blue));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    @include mobile {
      padding: 0;
      font-size: 22px;
      line-height: 24px;
    }
  }

  .featured_nft_image {
    width: 200px;
  }


  .artist {
    height: 600px;
    width: calc(100% - 300px);
    margin: auto;
    margin-top: 56px;
    background-color: #000 !important;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0), 0 0 0 1px rgba(0, 0, 0, 0);
    border-radius: 24px;
    transition: ease-in-out 0.3s;

    /* &:hover {
      box-shadow: 0 1px 50px 0 rgb(113, 25, 181), 0 0 10px 1px rgba(0, 0, 0, 0);

      .artist_details {
        //background-color: rgba(0, 0, 0, 0.614);
        border-radius: 0 0 24px 24px !important;
      }
    } */

    @include mobile {
      height: 519px;
      width: 100%;
    }

    .artist_details {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: left;
      position: absolute;
      left: 48px;
      right: 0;
      bottom: 30px;

      padding: 20px;
      border-radius: 24px !important;
      transition: ease-in-out 0.3s;

      .project_image img {
        max-width: 60px;
        border-radius: 50px;
      }

      p {
        font-size: 36px;
        font-weight: 600;
        color: #fff;
        &:hover {
          cursor: pointer;
        }

        span {
          display: block;
          color: rgba(255, 255, 255, 0.5);
          font-weight: 500 !important;
          font-size: 18px !important;
          line-height: 22px !important;
          padding: 10px 0;
        }
      }

      .nft_button {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #887cec;
      }

      .social {
        display: inline-flex;
        gap: 10px;

        img {
          width: 40px;
        }
      }
    }
  }


  .artist_lg {
    width: 100%;
    height: 637px;
    background-color: #000 !important;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0), 0 0 0 1px rgba(0, 0, 0, 0);
    border-radius: 24px;

    @include mobile {
      height: 519px;
      width: 100%;
    }

    .artist_details {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0px;

      padding: 20px;
      border-radius: 24px !important;

      p {
        font-size: 28px;
        color: #fff;
        text-align: center;

        span {
          display: block;
          color: rgba(246, 245, 255, 0.6);
          font-weight: 400 !important;
          font-size: 14px !important;
          line-height: 17px !important;
        }
      }

      a {
        color: #887cec;
        text-decoration: none;
      }

      .nft_button {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #887cec;
      }

      .social {
        display: inline-flex;
        gap: 10px;

        img {
          width: 40px;
        }
      }
    }
  }

  button {
    margin-top: 50px !important;
  }
}

.getStarted {
  margin-top: 100px;

  h2 {
    color: #fff;

    font-weight: 600;
    font-size: 42px;
    line-height: 44px;

    margin-top: 20px;
    margin-bottom: 30px;
  }

  h3 {
    color: #fff;

    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    text-align: center;

    margin-top: 20px;
    margin-bottom: 60px;
  }

  .card {
    padding: 80px 40px;
    background: rgba(255, 255, 255, 0.04);
    border-radius: 16px;
    background-image: url("/downloadbg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto;
    background-position: left;

    @include mobile {
      margin: 0 20px;
      background-image: url("/downloadbg.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto;
      background-position: top;
    }

    .grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      position: relative;
      align-items: center;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        width: 1px;
        background-image: linear-gradient(180deg, #0d0b27, rgba(255, 255, 255, 0.281), #0d0b27);
      }

      @include mobile {
        display: block;
        padding: 0;

        &::before {
          display: none;
        }
      }

      .content {
        max-width: 400px;
        margin: 0 auto;

        p {
          color: #fff;
        }

        h2 {
          font-family: 'Lexend';
          font-style: normal;
          border-radius: 15px;
          padding: 10px 10px 10px 0;

          font-weight: 700;
          font-size: 48px;
          line-height: 52px;
          letter-spacing: -0.03em;
        }
      }

      .form {
        margin: 0 50px;

        @include mobile {
          margin: 50px 0;
        }

        input {
          background: rgba(255, 255, 255, 0.03);
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 12px;
          color: #fff;
          padding: 20px 10px;
          margin: 10px 0;

          &::placeholder {
            color: rgba(255, 255, 255, 0.456) !important;
          }

          &:focus {
            background: #090817;
            border: 1px solid #6959E6;
            box-shadow: 0px 0px 8px rgba(0, 33, 188, 0.5);
          }
        }

        .dropdown {
          margin: 20px 0;
          color: #fff !important;

          div[role="listbox"] {
            background: rgba(255, 255, 255, 0.03);
            border: 1px solid rgba(255, 255, 255, 0.1);
            color: #fff;
            border-radius: 15px;
            padding: 20px 10px;

            i {
              font-size: 18px;
            }

            &:focus {
              background: #090817;
              border: 1px solid #6959E6;
              box-shadow: 0px 0px 8px rgba(0, 33, 188, 0.5);
              color: #fff !important;
            }
          }
        }

        button {
          margin-top: 20px;
          width: auto;
          background-color: rgba(136, 125, 234, 1);

          color: #fff;
          border-radius: 12px;
          padding: 15px;

          font-weight: 600;
          font-size: 16px;
          line-height: 19px;

          &:hover {
            background-color: rgb(107, 96, 211);
          }

          @include mobile {
            width: 100%;
          }
        }
      }
    }
  }
}


.textSection {
  background-image: url("/378621.png");
  background-repeat: no-repeat;
  background-position: left;
  background-size: 30%;
  padding: 200px 0;
  h3 {
    text-align: center;
    color: #fff;
  }
  p {
    text-align: center;
    color: #fff;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 34px;
  }
}