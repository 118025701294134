@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@500;600;700&display=swap');

@mixin mobile {
  @media (max-width: 900px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 901px) and (max-width: 1199px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin lg_desktop {
  @media (min-width: 1800px) {
    @content;
  }
}

.benefits {
  background-image: url("/assets/images/fansbg02.png");
  background-repeat: no-repeat;
  //background-position: center;
  background-size: 100% 100%;
  width: 100%;
  min-height: 800px;
  //height: 970px;
  padding: 50px;
  margin-bottom: 200px;

  &:hover {
    box-shadow: none !important;
    border: none !important;
  }

  .row {
    gap: 30px 0;
  }

  //margin: 100px 0;
  @include mobile {
    padding: 10px;
    margin-top: 30px;

    .column {
      width: 100% !important;
    }
  }

  @include tablet {
    padding: 20px 0;

    .column {
      width: 50% !important;
    }
  }

  b {
    color: #59d6e6;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;
  }


  h3 {
    padding: 20px;
    font-family: "Lexend";
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 0;
    text-align: left;
    letter-spacing: -0.02em;
    color: #fff0fa;

    @include mobile {
      padding: 0;
      font-size: 38px;
      line-height: 24px;
    }
  }

  .subHeading {
    padding: 20px;
    font-family: "Lexend";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
    text-align: left;
    letter-spacing: -0.02em;
    color: #fff;
    opacity: 0.7;

    @include mobile {
      padding: 10px 0;
    }
  }

  .content {
    padding: 0 !important;
  }

  .benefit {
    height: 400px;
    width: 100%;
    border-radius: 24px;
    position: relative;
    background: transparent;
    border: none !important;
    box-shadow: none !important;
    overflow: hidden !important;
    transform: translateY(0px) !important;

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(358deg, #000, transparent);
      border-radius: 24px !important;
      transition: ease-in-out 400ms;
    }
    
    .creator_type {
      position: absolute;
      display: inline-flex;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;
      top: 20px;
      right: 20px;
      border-radius: 4px;
      padding: 3px 8px;
      border: 1px solid #3B395A;
      background: #151331;

      span {
        color: var(--blue);
        /* background: linear-gradient(258deg, var(--purple), var(--blue));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; */
      }
    }

    @include mobile {
      width: 100%;
    }

    img {
      height: 100% !important;
      width: 100% !important;
      object-fit: cover !important;
      object-fit: contain;
      transition: ease-in-out 400ms;
    }

    .benefits_details {
      position: absolute;
      top: 300px;
      left: 0;
      border-radius: 24px !important;
      overflow: hidden !important;
      transition: ease-in-out 400ms;

      @include mobile {
        position: absolute;
        top: 220px;
        left: 0;
        .details {
          opacity: 1 !important;
        }
        .content {
          opacity: 1 !important;
        }
      }

      .content {
        opacity: 0;
        font-weight: 400 !important;
        font-size: 13px !important;
        line-height: 18px !important;
        color: #999CB6 !important;
        transition: ease-in-out 400ms;
      }

      .details {
        //opacity: 0;
        padding: 20px;
        transition: ease-in-out 400ms;
      }

      p {
        color: #fff;
        font-size: 18px !important;
        font-weight: 700;
        text-align: left;

        padding: 0 10px;
        margin-top: 20px;

        b {
          font-size: 18px !important;
          color: #fff;
          font-weight: bold;
        }
      }
    }

    &:hover {
      background: transparent !important;
      box-shadow: 0 1px 50px 0 rgb(113, 25, 181), 0 0 10px 1px rgba(0, 0, 0, 0) !important;
      .benefits_details {
        position: absolute;
        top: 220px;
        left: 0;

        .details {
          opacity: 1;
          bottom: 0;
        }

        .content {
          opacity: 1;
        }
      }
    }
  }
}