@mixin mobile {
  @media (max-width: 900px) {
    @content;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 200px;
  width: 100%;
  gap: 20px;

  h1 {
    color: white;
    text-align: center;
  }
}
.content_support {
  display: flex;
  flex-direction: row;
  gap: 20px;
  @include mobile {
    gap: 10px;
  }
}
.middle_img {
  img {
    object-fit: cover;
    height: 590px;
    width: 100%;
  }

  @include mobile {
    img {
      height: 450px;
    }
  }
}
.messages_right {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  gap: 60px;
  top: 100px;
  @include mobile {
    gap: 10px;
  }
  .message:nth-child(2) {
    position: relative;
    right: 40px;
  }
}

.message_left {
  background-image: url("https://loop-markets.directus.app/assets/241133f1-3656-4d74-8659-c5b87554c12d");
  background-size: cover;
  p {
    color: rgb(231, 231, 231);
    font-size: 14px;

    @include mobile {
      font-size: 12px;
    }
  }

  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: fit-content;
  padding: 15px 15px;
  position: relative;
  top: 50px;

  @include mobile {
    width: 150px;
    padding: 10px 10px;
  }
}
.message {
  background-image: url("https://loop-markets.directus.app/assets/241133f1-3656-4d74-8659-c5b87554c12d");
  background-size: cover;
  p {
    color: rgb(231, 231, 231);
    font-size: 14px;

    @include mobile {
      font-size: 12px;
    }
  }

  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: fit-content;
  padding: 15px 15px;

  @include mobile {
    width: 150px;
    padding: 10px 10px;
  }
}
@media (max-width: 570px) {
  .message_left {
    position: relative;
    top: -10px !important;
    left: 10%;
  }
  .messages_right {
    position: relative;
    top: 40px;
    right: 30px;
    .message:nth-child(2) {
      position: relative;
      right: 0px;
    }
  }
  .middle_img {
    img {
      height: 450px;
      width: auto;
    }
  }
}
@media (max-width: 500px) {
  .message_left {
    position: relative;
    bottom: 10px;
    left: 15%;
  }
  .messages_right {
    position: relative;
    top: 40px;
    right: 50px;
    gap: 30px;
  }
  .middle_img {
    img {
      height: 450px;
      width: auto;
    }
  }
}
@media (max-width: 455px) {
  .message_left {
    position: relative;
    bottom: 10px;
    left: 15%;
    width: 140px;
  }
  .messages_right {
    position: relative;
    top: 40px;
    right: 60px;
    gap: 60px;
  }
  .message {
    width: 140px;
  }
  .middle_img {
    img {
      height: 450px;
      width: auto;
    }
  }
}
@media (max-width: 410px) {
  .messages_right {
    position: relative;
    top: 55px;
    right: 80px;
    gap: 50px;
  }
  .message {
    width: 140px;
  }
  .middle_img {
    img {
      height: 450px;
      width: auto;
    }
  }
}
@media (max-width: 370px) {
  .message_left {
    position: relative;
    bottom: 10px;
    left: 18%;
    width: 140px;
  }
  .messages_right {
    position: relative;
    top: 55px;
    right: 100px;
    gap: 70px;
  }
  .message {
    width: 140px;
  }
  .middle_img {
    img {
      height: 450px;
      width: auto;
    }
  }
}

@media (max-width: 340px) {
  .container h1 {
    margin-bottom: 40px;
  }
  .message_left {
    position: relative;
    bottom: 30px;
    left: 22%;
    width: 140px;
  }
  .messages_right {
    position: relative;
    top: 50px;
    right: 110px;
    gap: 70px;
  }
  .message {
    width: 140px;
  }
  .middle_img {
    img {
      height: 450px;
      width: auto;
    }
  }
}
