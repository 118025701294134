.post-link{
  text-decoration: none;
}
.post-preview {
  background: var(--background-light);
  border-radius: 6px;
  margin: 1vh 0;
  // height: 210px;
  padding: 0 !important;
  @media (max-width: 990px) {
    height: auto;
  }
  @media (max-width: 550px) {
    font-size: 0.8em;
  }
  &:hover {
    cursor: pointer;
  }
  &-info {
    // padding: 1rem 0;
    img.post-preview-avatar {
      max-width: 70px !important;
      width: 100%;
      min-width: 30px !important;
    }
  }
  &-title {
    @media (max-width: 990px) {
      // height: auto;
    }
  }
  &-subtitle {
    margin-bottom: '0';
    margin-top: 20px;
    color: #c0c0c1 !important;
    @media (max-width: 990px) {
      // height: auto;
    }
  }
  .content-img-container {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  .content-img {
    background-size: cover;
    background-position: center;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    min-height: 150px;
    //max-height: 300px;
    @media (max-width: 990px) {
      width: 100%;
      height: 75%;
      border-radius: 6px 6px 0 0;
      //margin: 0 5%;
    }
    @media (max-width: 550px) {
      height: 50%;
    }
  }
  .dark-text {
    font-size: 0.75em;
    color: var(--title-dark);
    .fal {
      padding: 0 0.7rem;
    }
  }
}