.controls-panel {
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  padding: 10px 0;
  background: var(--back-color);
  border-radius: 10px;
  border: solid 1px rgba(255,255,255,.08);
  a {
    width: 100%;
  }
  .user {
    display: flex;
    flex-direction: row;
    .ui.avatar.image,
    .ui.avatar.image img,
    .ui.avatar.image svg,
    .ui.avatar.images .image,
    .ui.avatar.images img,
    .ui.avatar.images svg {
      width: 2.5em;
      height: 2.5em;
    }
  }
  & .user-info {
    margin: 0;
    display: flex;
    flex-direction: column;
    color: var(--text-color);
    & .name {
      font-size: 1.1rem
    }
    & .username {
      opacity: 0.7;
      font-size: 0.8rem;
    }
  }
  & .control-btn {
    height: 100%;
    justify-content: space-around;
    width: 100%;
    &:hover {
      cursor: pointer;
    }
  }
  &-container {
    //min-width: 20vw;
  }
  & i {
    font-size: 1.75em;
    color: var(--text-color);
  }
}