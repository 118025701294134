@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@500;600;700&display=swap');

@mixin mobile {
    @media (max-width: 900px) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: 901px) and (max-width: 1199px) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin lg_desktop {
    @media (min-width: 1800px) {
        @content;
    }
}

.cards {
    .column {
        @include mobile {
            width: 100% !important;
        }
    }
    b {
        color: #59d6e6;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        /* identical to box height */

        text-align: center;
        letter-spacing: 0.06em;
        text-transform: uppercase;
    }

    .container {
        max-width: 1440px !important;
        margin: auto;
    }

    h3 {
        padding: 20px;
        margin-bottom: 56px;
        font-family: "Lexend";
        font-style: normal;
        font-weight: 600;
        font-size: 58px;
        line-height: 56px;
        text-align: center;
        letter-spacing: -0.02em;
        color: #fff0fa;

        span {
            background: -webkit-linear-gradient(358deg, var(--purple), var(--blue));
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        @include mobile {
            padding: 0;
            font-size: 28px;
            line-height: 24px;
        }
    }

    .featured_nft_image {
        width: 200px;
    }

    .collection {
        width: 100%;
        width: 532px;
        background-color: #000 !important;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0), 0 0 0 1px rgba(0, 0, 0, 0);
        border-radius: 24px;

        @include mobile {
            height: 519px;
            width: 100%;
        }

        .collection_details {
            text-align: center;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0px;

            padding: 20px;
            background: linear-gradient(0deg,
                    rgba(0, 0, 0, 0.8) 20%,
                    rgba(14, 14, 24, 0.6),
                    70%,
                    rgba(23, 23, 40, 0) 100%);
            border-radius: 24px !important;

            .project_image img {
                max-width: 60px;
                border-radius: 50px;
            }

            p {
                color: #fff;

                span {
                    color: rgba(246, 245, 255, 0.6);
                    font-weight: 400 !important;
                    font-size: 14px !important;
                    line-height: 17px !important;
                    text-align: center !important;
                }
            }

            .nft_button {
                font-weight: 600;
                font-size: 14px;
                line-height: 18px;
                text-align: center;
                color: #887cec;
            }
        }
    }

    .content {
        padding: 0 !important;
    }

    .benefit {
        width: 100%;
        background: transparent;
        box-shadow: none;
        border-radius: 24px;

        @include mobile {
            width: 100%;
        }

        .benefits_details {
            border-radius: 24px !important;

            &:hover {
                .image_container {
                    background: #03011e;
                    box-shadow: 0 1px 50px 0 rgb(113, 25, 181), 0 0 10px 1px rgba(0, 0, 0, 0);
                    border-radius: 24px;

                    img {
                        transform: scale(1.4) rotate(-10deg);
                    }
                }
            }

            .image_container {
                //height: 350px;
                width: 100%;
                margin: 20px 0;
                overflow: hidden;
                display: flex;
                transition: ease-in-out 0.3s;

                img {
                    width: 100%;
                    object-fit: contain;
                    transition: ease-in-out 0.3s;
                }
            }

            p {
                color: rgba(246, 245, 255, 0.6);
                font-size: 18px !important;
                text-align: left;

                padding: 0 10px;
                margin-top: 20px;

                b {
                    font-size: 18px !important;
                    color: #fff;
                    font-weight: bold;
                }
            }

            .nft_button {
                font-weight: 600;
                font-size: 14px;
                line-height: 18px;
                text-align: center;
                color: #887cec;
            }
        }
    }
}