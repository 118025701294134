@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@500;600;700&display=swap');

@mixin mobile {
  @media (max-width: 900px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 901px) and (max-width: 1199px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin lg_desktop {
  @media (min-width: 1800px) {
    @content;
  }
}

.discover {
  width: 100%;
  //height: 970px;
  padding: 200px 50px;
  text-align: center;

  //margin: 100px 0;

  @include mobile {
    padding: 100px 10px;

    .column {
      width: 100% !important;
    }
  }

  @include tablet {
    .row {
      display: flex;
      justify-content: center;
    }
  }

  b {
    color: #59d6e6;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.06em;
    //text-transform: uppercase;
  }

  .discoverContainer {
    margin-top: 63px;
  }

  .header {

    h3 {
      padding: 0 20px;
      font-family: "Lexend";
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 53px;
      text-align: left;
      letter-spacing: -0.02em;
      color: #fff0fa;
  
      span {
        background: -webkit-linear-gradient(358deg, var(--purple), var(--blue));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
  
      @include mobile {
        padding: 0;
        font-size: 28px;
        line-height: 24px;
      }
    }

    p {
      color: #fff;
      padding: 0 20px;
      text-align: left;
      @include mobile {
        padding: 0;
      }
    }
  }

  .items {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    margin-bottom: 72px;

    @include mobile {
      margin-bottom: 0;
    }

    .item {
      width: 270px;
      height: 455px;
      flex-shrink: 0;
      margin: auto !important;
      margin-bottom: 50px !important;

      flex-basis: calc(25% - 20px);


      border-radius: 16px !important;
      background-color: #000;
      background-size: cover;
      background-repeat: no-repeat;
      padding: 20px !important;

      @include mobile {
        width: 100%;
        height: 455px;
        margin-top: 20px !important;

        flex-basis: 100%;
      }

      .card {
        width: 100%;
        height: 100%;
        //max-height: 250px;
        background-color: transparent !important;
        box-shadow: none;
        padding: 5px;
        margin-bottom: 40px;
        overflow: hidden;

        @include mobile {
          margin: auto;
        }

        .content {
          height: 100%;

          .details {
            width: 100%;
            text-align: left;
            padding: 20px 0;
            .title {
              font-weight: 600;
              font-size: 18px;
              line-height: 24px;
              color: #F6F5FF;
            }
            .sub_title {
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;
              letter-spacing: 0.1em;
              //text-transform: uppercase;
              color: rgba(246, 245, 255, 0.6);
              margin-top: 10px;
            }
            .nft_count {
              font-weight: 500;
              font-size: 16px;
              line-height: 19px;
              letter-spacing: 0.02em;
              color: #FAFAFA;
            }
          }
        }

        .image_container {
          width: 160px;
          height: 220px;
          flex-shrink: 0;
          border-radius: 8px;
          margin: 20px auto;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px;
          }

          @include mobile {
            width: 160px;
            height: 220px;
          }
        }
      }

      img {
        width: 100%;
      }
    }
  }
}