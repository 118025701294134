.socialDropdown-btn {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 113px;
    height: 36px;
    top: -1308px;
    left: 35876px;
    box-sizing: border-box;
    background: rgb(27, 27, 27);
    //border-width: 1px;
    //border-style: solid;
    //border-color: rgb(1, 205, 253);
    //border-image: initial;
    border: none;
    border-radius: 6px;
    padding: 0 !important;
    color: #fff !important;
    z-index: 999;

    img {
        width: 18px;
    }
    .title {
        width: 45px;
        height: 13px;
        text-decoration: none;
        font-family: Lexend;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 12px;
        /* identical to box height */
    
        display: flex;
        align-items: center;
        text-transform: capitalize;
        display: flex;
        justify-content: space-around;
        opacity: 1;
        cursor: pointer;
    
    
        color: #FFFFFF;
    }
}

.socialDropdown {
    background: #1B1B1B;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    // z-Index: 5;
    position: relative;
    padding: 10px;
    transform: translateY(0);
    -webkit-transition-delay: 2s;
    transition-delay: 2s;

    .title {
        width: 45px;
        height: 13px;
        text-decoration: none;
        font-family: Lexend;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 12px;
        /* identical to box height */
    
        display: flex;
        align-items: center;
        text-transform: capitalize;
        display: flex;
        justify-content: space-around;
        opacity: 1;
        cursor: pointer;
    
    
        color: #FFFFFF;
    }

    .invisibleDiv {
        position: absolute;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    
    .list {
        padding: 10px 0;
    }
    
    .socialLink {
        padding: 8px;
        display: flex;
        justify-content: space-between;
        opacity: 1;
        cursor: pointer;
    
        img {
            width: 18px;
        }
    
    }
    
    .btn {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 113px;
        height: 36px;
        top: -1308px;
        left: 35876px;
        border-radius: 6px;
        background: #1B1B1B;
    
        //border: 1px solid #01CDFD;
        box-sizing: border-box;
        border-radius: 6px;
    
        img {
            width: 18px;
    
        }
    
    }
}