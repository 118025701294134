@import '../../../../styles/mixins';

.countdown {
  width: 100%;
  min-height: 600px;

  //background-image: url('https://loop-markets.directus.app/assets/c9e464dc-4458-4c98-bbde-6f42269c4785.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;

  border-radius: 10px;

  @include mobile {
  width: 100%;
  min-height: 300px;
  }

  h3 {
    color: #fff;
    text-align: center;
  }
  
  .time {
    margin: auto;
    border-radius: 15px;
    padding: 20px;
    width: 100%;

    h6 {
      font-family: 'Lexend';
      font-style: normal;
      font-weight: 900;
      font-size: 34px;
      line-height: 80px;
      text-align: center;

      letter-spacing: 0.07em;
      text-transform: uppercase;

      color: #FFFFFF;

      span {
        opacity: 0.4;
        padding: 0 15px;
      }
    }

    .time_detail {
      font-family: 'Lexend';
      font-style: normal;
      font-weight: 400;
      font-size: 12px !important;
      line-height: 0;
      text-align: center;

      letter-spacing: 0.07em;
      text-transform: uppercase;

      color: #8D8D8D;

      span {
        opacity: 0.4;
        padding: 0 15px;
      }
    }

    p {
      font-family: 'Lexend';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      /* identical to box height, or 158% */

      text-align: center;
      letter-spacing: 0.13em;
      text-transform: uppercase;

      color: #8D8D8D;
    }

    @include mobile {
      h6 {
        font-size: 18px !important;

        span {
          opacity: 0.4;
          padding: 0 10px;
        }
      }

      .time_detail {
        font-size: 10px !important;

        span {
          opacity: 0.4;
          padding: 0 10px;
        }
      }
    }
  }
}