@import '../../../styles/mixins';

h3 {
  font-weight: 600;
  font-size: 48px;
  line-height: 53px;
  letter-spacing: -0.03em;
  padding: 15px 0 20px 0;
  color: #FFFFFF;
  text-align: left;

  span {
    background: -webkit-linear-gradient(358deg, #e659d6, #8877ea, #86e2ee);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  p {
    color: #fff !important;
    font-weight: 300 !important;
    font-size: 22px !important;
    padding: 15px 0 !important;
  }

  @include mobile {
    padding: 0;
    font-size: 28px;
    line-height: 24px;
  }
}


h4 {
  font-weight: 600;
  font-size: 38px;
  line-height: 53px;
  letter-spacing: -0.03em;
  padding: 15px 0 20px 0;
  color: #FFFFFF !important;
  text-align: left;
}

.collections {
  margin-bottom: 200px;
  @include mobile {
    margin-bottom: 100px;
  }

  .collection_section {
    padding: 20px;
    padding-bottom: 50px;
    border: solid 1px #ffffff52;
    border-radius: $border-radius;
  }

  .collection_btn {
    font-size: 24px;
    margin: 30px auto;
    text-align: center;
    display: block;
  }

  .item {
    display: flex;
    //align-items: center;
    justify-content: space-between;
    gap: 20px;

    @include mobile {
      display: block;
    }

    .right {
      width: 50%;
      padding: 0;

      @include mobile {
        width: 100%;
      }
    }

    .collectionImage {
      border: 1px solid #22203B;
      position: relative;
      border-radius: 8px;

      width: 600px;
      height: 750px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .video {
        height: 100%;
      }

      .loop_player {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .playButton {
        width: 100px;

        img {
          width: 100px;
          height: 100px;
          margin: auto;
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          pointer-events: all;
          transition: ease-in-out 400ms;

          &:hover {
            cursor: pointer;
            transform: scale(0.9);
          }
        }
      }

      @include mobile {
        width: 100%;
        height: 400px;

        margin: 50px 0;
      }
    }

    h4 {
      font-weight: 600;
      font-size: 48px;
      line-height: 53px;
      letter-spacing: -0.03em;

      padding: 15px 0 20px 0;

      color: #FFFFFF;
    }

    p {
      margin-top: 15px;
      font-weight: 300;
      font-size: 16px;
      line-height: 28px;
      color: #C1C4D7;
    }
  }
}

.card {
  margin: 200px 0;

  @include mobile {
    margin: 0;
  }

  .right {
    span {
      display: block;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.1em;
      text-transform: uppercase;

      color: #787B9C;
    }

    @include mobile {
      margin-top: 50px;
    }
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mobile {
      display: block;
    }

    .right {
      width: 50%;

      @include mobile {
        width: 100%;
      }
    }

    .collectionImage {
      border: 1px solid #22203B;
      position: relative;
      border-radius: 8px;

      max-width: 1000px;
      width: 100%;
      height: 600px;
      margin: auto;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .video {
        height: 100%;
      }

      .loop_player {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .playButton {
        width: 100px;

        img {
          width: 100px;
          height: 100px;
          margin: auto;
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          pointer-events: all;
          transition: ease-in-out 400ms;

          &:hover {
            cursor: pointer;
            transform: scale(0.9);
          }
        }
      }

      @include mobile {
        width: 100%;
        height: 400px;

        margin: 50px 0;
      }
    }

    h4 {
      font-weight: 600;
      font-size: 48px;
      line-height: 53px;
      letter-spacing: -0.03em;

      padding: 15px 0 20px 0;

      color: #FFFFFF;
    }

    p {
      margin-top: 15px;
      font-weight: 300;
      font-size: 16px;
      line-height: 28px;
      color: #C1C4D7;
    }
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}



.detailBenefits {
  display: flex;
  align-items: center;
  gap: 15%;
  //justify-content: space-between;

  @include mobile {
    display: block;
  }

  .details {
    width: 100%;
    b {
      font-weight: bold;
      color: #fff;
    }
  }

  .left {
    width: 50%;

    @include mobile {
      width: 100%;
    }
  }

  .right {
    @include mobile {
      margin-top: 50px;
    }

    span {
      font-weight: 600;
      font-size: 18px;
      line-height: 13px;
      letter-spacing: 0.1em;
      text-transform: uppercase;

      color: #FFFAFD;
    }

    p {
      font-weight: 400 !important;
      padding-top: 4px !important;
    }
  }

  @include mobile {
    width: 100%;
  }

  h3 {
    font-weight: 600;
    font-size: 48px;
    line-height: 53px;
    letter-spacing: -0.03em;
    color: #FFFFFF;
  }

  p {
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
    color: #C1C4D7;

    margin-top: 10px;
  }

}


.cta_card {
  width: 100%;
  height: 200px;

  border-radius: 10px;

  margin: auto;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @include mobile {
    background-image: none !important;
  }

  a {
    width: 300px;
    height: 100%;

    display: flex;
    align-items: center;
    margin: auto;

    .cta_button {
      width: 300px;
      height: 50px;

      background-color: #59d6e6;
      border: 1px solid #59d6e6;

      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;

      font-size: 24px;

      &:hover {
        cursor: pointer;
        background-color: #59d6e6;
      }
    }
  }
}

.countdown {

  text-align: center;
  margin: 100px 0;

  .button {
    width: 100% !important;
    max-width: 400px !important;
    height: auto !important;
    font-size: 22px;

  }
}