
.ui.container.your-trybe-wrapper {
  padding: 5vh 4vw;
  width: 100%!important;
  max-width: 100vw!important;
  min-height: 100vh;
  //background-color: var(--back-color);
  @media #{$for-phone-only} {
    padding: 5px 0 0 0!important;
  }
  .sticky {
    margin-top: 10px;
  }
  .person-summary {
    background: var(--wide-screen-profile-gradient);
    border-radius: 6px;
    margin-top: 2vh;
    margin-bottom: 2vh;
    @media (max-width: 992px) {
      margin-top: 13px;
      background: var(--narrow-screen-profile-gradient);
    }
    .person {
      .avatar {
        padding-left: 20%;
        @media (max-width: 992px) {
          padding-left: 0;
          padding: 0;
        }
        .circle-border {
          position: relative;
          width: 9vw;
          height: 9vw;
          max-width: 16vh;
          max-height: 16vh;
          border-radius: 50%;
          min-height: 100px;
          min-width: 100px;
          border: 1px solid var(--green);
          padding: $padding;
          @media (max-width: 992px) {
            margin: 0 auto;
            padding: 0;
          }
          .circle {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: var(--green);
            background-size: cover; //background-image: url(https://trybe.social/wp-content/uploads/2018/09/4-COUNCIL-1-100x100.png);
          }
          .online-mark {
            position: absolute;
            right: 7%;
            bottom: 7%;
            width: 1.2vw;
            height: 1.2vw;
            background-color: var(--green);
            border: 3px solid var(--back-color);
            border-radius: 0.6vw;
            box-shadow: 0px 0px 20px var(--back-color);
            @media (max-width: 992px) {
              width: 2vw;
              height: 2vw;
              border-radius: 1vw;
            }
            @media (max-width: 576px) {
              width: 4vw;
              height: 4vw;
              border-radius: 2vw;
            }
          }
        }
      }
      .description {
        display: flex;
        flex-flow: column;
        justify-content: center;
        @media (max-width: 992px) {
          text-align: center;
        }
        .name {
          font-weight: 700;
        }
        .nick {
          color: var(--title-dark);
          padding: 1em 0;
        }
        .rank {
          span:first-child {
            font-weight: 600; // color: var(--title-dark);
          }
          span:last-child {
            font-weight: 300; // color: var(--title-dark);
          }
        }
      }
    }
    .stats {
      .column {
        display: flex;
        flex-flow: column;
        text-align: center;
        padding-top: $padding*2;
        span.title {
          font-size: 11px;
        }
        span.value {
          font-weight: 700;
          color: var(--green);
          font-size: 1.2em;
        }
      }
    }
  }
  .content {
    .eleven.wide.column.main-wrapper {
      padding: $padding $padding*6 $padding $padding;
      main {
        background-color: var(--message-summary-header);
        .navbar {
          border-bottom: 2px solid var(--title-dark);
          padding: $padding $padding*2;
          .column {
            display: flex;
            justify-content: space-between;
            .left {
              display: flex;
              .nav-el {
                padding: $padding;
              }
            }
            .right {
              display: flex;
              .ui.item.dropdown {
                padding: $padding;
              }
            }
          }
        }
        .form {
          padding: $padding;
        }
      }
    }
    .two.wide.column.side-control{
      .ui.fixed.top.sticky {
        @media #{$for-tablet-landscape-down} {
          background-color: var(--back-color);
          >.row{
            text-align: center;
          }
        }
      }
    }
  }

}


// .ui.grid.page-wrapper {
//   padding: 110px 0 0 0;
//   background: linear-gradient(    112.25deg, #252D49 16.48%, #32222A 85.74%);
//         padding-left: 40px;
//         width: 100%;
//         min-height: 100vh;
//         margin: 0;
// }