@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@500;600;700&display=swap');
@import "./styles/mixins";

.faqBody {
  margin: 100px 0;
  padding: 30px;
  .ui.accordion .title:not(.ui) {
    background: #151331 !important;
    padding: 30px 10px;

    font-weight: 600;
    font-size: 24px;
    line-height: 32px;

    color: #F6F5FF;
  }

  .heading {
    font-weight: 700;
    font-size: 56px;
    line-height: 64px;
    /* identical to box height, or 114% */
    
    text-align: center;
    letter-spacing: -0.03em;
    
    color: #FFFFFF;
  }
}