@mixin mobile {
  @media (max-width: 900px) {
    @content;
  }
}
.container_grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 200px;
  overflow: hidden;
  gap: 100px;
  @include mobile {
    margin-top: 150px;
  }
}

.home_grid {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
  width: 100%;
  gap: 14px;
  flex-wrap: wrap;
}

.home_img_card {
  display: flex;
  flex-direction: column;
  width: 260px;
  border-radius: 20px;
  gap: 8px;
  margin: 10px;

  video {
    border-radius: 10px;
  }

  span {
    color: white;
    font-size: 16px;
  }
  p {
    font-size: 14px;
    text-align: left;
    font-family: "Lexend", sans-serif !important;
    font-weight: 300;
    color: rgb(228, 228, 228);
  }
}
