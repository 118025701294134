button.replyComment {
  border: none !important;
  background: #c83e93 !important;
  color: #000000 !important;
  font-weight: 500 !important;
  padding-left: 3rem !important;
  padding-right: 3rem !important;
  border-radius: 4px;
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  cursor: pointer;
  border-radius: 10px;
  
  &:disabled {
    background: #7a285b !important;
    color: #fff !important;
  }
}
.buttons.cz-leaving-repy {
  justify-content: flex-end;
}