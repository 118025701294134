@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@500;600;700&display=swap');

@mixin mobile {
  @media (max-width: 900px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 901px) and (max-width: 1199px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin lg_desktop {
  @media (min-width: 1800px) {
    @content;
  }
}

.button {
  width: 112px !important;
  padding: 15px 24px !important;
}

.button_outline {
  background: transparent !important;
  color: #887DEA !important;
  border: solid 2px #887DEA !important;
  border-radius: 12px !important;

  &:hover {
    background: #887DEA !important;
    color: #fff !important;
  }
}

.btn_sm {
  width: fit-content !important;
  //width: 150px !important;
}