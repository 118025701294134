@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@500;600;700&display=swap");
@import "../../styles/mixins";

.faqBody {
  margin: 100px 0;
  min-height: 800px;
  max-width: 1440px;
  margin: auto;
  margin-bottom: 100px;
  padding: 0 20px;

  .heading {
    font-weight: 700;
    font-size: 56px;
    line-height: 64px;
    /* identical to box height, or 114% */

    text-align: center;
    letter-spacing: -0.03em;

    color: #ffffff;

    margin: 80px 0 !important;
    padding-top: 40px;

    @include mobile {
      font-size: 36px;
      line-height: 42px;
    }
  }
}

.faqs {
  .AccordionHeader {
    background: #2d2958 !important;
    &:hover {
      background: #2d2958 !important;
      cursor: default !important;
    }
  }
  .Accordion {
    width: 100%;
    background: #151331;
    position: relative;
    overflow: hidden;
    transition: ease-in-out 300ms;

    &:hover {
      background: #221f42;
      cursor: pointer;
    }

    &:not(:first-of-type) {
      border-top: solid 1px #ffffff1b;
    }

    &:first-of-type {
      border-radius: 15px 15px 0 0 !important;
    }

    &:last-of-type{
      border-radius: 0 0 15px 15px !important;
    }

    .active {
      background: #221f42;
      .icon {
        position: absolute;
        top: 50px;
        right: 50px;
        @include mobile {
          width: 40px;
        }
      }
    }

    .content {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 52px;

      max-width: calc(80% - 50px);

      padding: 40px 60px;

      color: #fff;

      @include mobile {
        width: 100%;
        max-width: 100%;
      }

      .icon {
        position: absolute;
        top: auto;
        right: 50px;
        @include mobile {
          top: 10px;
          right: 10px;
          width: 25px;
        }
      }

      h3 {
        font-weight: 600;
        font-size: 22px;
        line-height: 30px;

        color: #f6f5ff;
        @include mobile {
          font-size: 20px;
        }
      }

      p {
        font-weight: 300;
        font-size: 16px;
        line-height: 28px;

        color: #c1c4d7;
        img {
          max-width: 800px;
        }
      }
    }
  }

  .card {
    .title {
      padding: 30px 0 !important;

      .ui.accordion .title:not(.ui) {
        background: #1f178b !important;
      }
    }
  }
}