.copyright {
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.015em;
  color: #88859b !important;
  text-align: center;
  padding: 50px 0;
}
.footer_wrap {
  max-width: 1440px;
  margin: auto;
  padding: 50px 10px;
  border-top: 2px;
  border-bottom: 2px;
  border-left: 0;
  border-right: 0;
  border-style: solid;
  border-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      #ffffff1b,
      rgba(255, 255, 255, 0)
    )
    1;
  .emailList {
    width: 100%;
    input {
      background: #161529 !important;
      border-radius: 12px !important;
      padding: 20px 15px;
    }
  }
  footer {
    border-top: 1px solid #e5e7ea;
    padding-top: 51px;
    padding-bottom: 21px;
    h4 {
      font-size: 12px;
      line-height: 1.5;
      font-weight: 600;
      color: #2c2c38;
      margin-bottom: 12px;
    }

    .ui.list {
      margin-bottom: 36px;
    }

    .ui.list .list > a.item,
    .ui.list > a.item {
      font-size: 13px;
      color: #4dd4b6 !important;
      line-height: 1.54;
      font-weight: 500;
    }

    button {
      padding: 10px !important;
      margin: 5px !important;
      .svg-inline--fa {
        font-size: 1.7em;
      }
    }

    .telegram {
      color: white !important;
      background: #0088cc !important;
    }

    .discord {
      color: white !important;
      background: #7289da !important;
    }
  }
}

.social-footer-section {
  .social-footer {
    ul {
      padding: 0;
      display: flex;
      li {
        list-style: none;
        display: inline-block;
        margin-right: 10px;

        a {
          display: inline-flex;
          width: 42px;
          height: 42px;
          background: #171f36;
          border-radius: 40px;

          .instagram {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 42px;
            height: 42px;
            background: #171f36;
            border-radius: 40px;
            opacity: 0.5;
          }

          img {
            width: 21px;
            margin: auto;
            opacity: 0.5;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .footer-wrapper footer {
    .ui.list {
      margin-bottom: 10px;
    }
  }

  .social-footer-section {
    .social-footer {
      ul {
        padding: 0;
        text-align: center;
        margin-top: 20px;

        li {
          margin: 0 5px;
        }
      }
    }
  }
}
