.sidebar {
  min-width: 300px !important;
  overflow-x: hidden;

  .active {
    width: 100%;
    color: var(--purple) !important;
    //background: #232240;
    //border: 1px solid #383755;
    border-radius: 8px;

    @include mobile {
      width: auto;
    }
  }

  .nav-link {
    width: 100%;

    position: relative;
    margin: 10px;
    margin-left: 20px !important;
    padding: 8px 11px;

    border: 1px solid transparent;
    border-radius: 8px;

    @include mobile {
      width: auto;
    }

    a {
      display: block;
      color: #fff !important;
      text-align: left;
      font-size: 20px;
      transition: 400ms ease-in-out;

      &:hover {
        color: #fff !important;
      }
    }

    &:hover {
      color: #fff !important;
      //background: #232240 !important;
      border: 1px solid #383755 !important;
      border-radius: 8px;
    }
  }

  .active {
    .navbar_dropdown_content {
      display: block !important;
    }
  }

  .navbar_dropdown {
    &:focus {
      .navbar_dropdown_content {
        display: block !important;
      }
    }

    .nav_item {
      width: 100% !important;
      gap: 10px;

      color: #FFF !important;

      display: flex;
      align-items: center;
      text-align: left;
      background: transparent !important;

      &::after {
        content: '\25bc';
        margin-left: 0;
        font-size: 10px;
        color: #787B9C;
      }
    }

    .navbar_dropdown_content {
      display: none;
      border-radius: 6px;
      font-size: 14px;
      margin-top: 5px;
      padding: 0;
      list-style: none;
      text-align: left;
      transition: all 0.5s ease;

      li {
        padding: 5px 0;
      }

      span {
        color: var(--purple) !important;
      }

      .dropdown-item {
        text-decoration: none;
        display: block;
        justify-content: flex-start !important;
      }

      .disabled {
        a span {
          color: #767676 !important;
        }
      }

      li:first-child {
        display: block !important;
      }
    }
  }
}

.social-sidebar-section {
  margin-top: 100px !important;

  .socials {
    margin: auto;

    ul {
      padding: 0;

      li {
        list-style: none;
        display: inline-block;
        //margin-right: 30px;
        padding: 0 15px;

        a {
          display: block;

          img {
            width: 32px;
            display: inline-block;
          }
        }
      }
    }
  }
}


.cz-expanded-items a span {
  position: relative;
  display: flex;
  align-items: center;
}

.cz-expanded-items a span img:nth-child(2) {
  position: absolute;
  opacity: 0;
}

.cz-expanded-items a span.cz-sidebarActive img {
  opacity: 0;
}

.cz-expanded-items a span.cz-sidebarActive img:nth-child(2) {
  opacity: 1;
}

a.item.cz-sidebarActive span {
  color: #c83e93;
}

.sidebar-wrapper {
  height: 100vh;
  width: 100%;
  // padding-top: $header-height + 4vh;
  position: relative;
  top: 0;
  left: 0;

  .ui.segment.pushable {
    width: 100%;
    height: 100%;
    margin: 0;
    border: none;
    overflow: hidden;

    >div:first-child {

      .ui.segment,
      .ui.inverted.segment,
      .ui.primary.inverted.segment {
        background-color: transparent;
      }

      .segment {
        margin: 0;
      }

      .sidebar-main {
        .ui.inverted.segment.menu-list-wrapper {
          margin-bottom: 100px;
          color: var(--text-color);

          .checkbox {
            label {
              color: var(--text-color);
            }
          }

          .item {
            text-align: left;

            a {
              color: var(--text-color) !important;
            }

            a:hover {
              color: var(--green) !important;
            }
          }
        }

        .trybe_runner {
          margin-top: 3rem;
          border: solid 5px var(--text-color)
        }
      }

      .sidebar-notifications {
        .ui.inverted.segment.section-title {
          .ui.header {
            color: var(--green);
          }
        }
      }

      .sidebar-chat-summary {
        .ui.inverted.segment.button-back-wrapper {
          //background-color: black!important;
          padding: 0.5rem;
          display: flex;
          margin: 0;
          justify-content: flex-end;
          align-items: center;

          .ui.button {
            background-color: transparent;
            color: var(--button-light);
            margin: 0;
            padding: 0.5rem;
            margin-right: 2rem;

            i {
              font-size: 2rem;
              line-height: 2rem;
              margin: 0;
            }
          }

          .button-chat-fullscreen {
            color: var(--button-light);
          }
        }

        .ui.feed.channel-list-wrapper {
          width: 100%;
        }
      }

      //max-height: 100vh - $header-height!important;
      //overflow-y: hidden!important;
    }

    @media only screen and (max-width: 991px) {

      .sidebar a,
      .sidebar button {
        font-size: 20px !important;
        width: auto;
      }
    }

    @media #{$for-phone-only} {
      .sidebar-main {
        .ui.inverted.segment.menu-list-wrapper {
          overflow: scroll;
          margin-bottom: 100px;
          color: var(--text-color);

          .checkbox {
            label {
              color: var(--text-color);
            }
          }

          .item {
            text-align: left;

            a {
              color: var(--text-color) !important;
            }

            a:hover {
              color: var(--green) !important;
            }
          }
        }

        .trybe_runner {
          margin-top: 3rem;
          border: solid 5px var(--text-color)
        }
      }

      ::-webkit-scrollbar {
        width: 0px;
        /* remove scrollbar space */
        background: transparent;
        /* optional: just make scrollbar invisible */
      }
    }

    .pusher {
      height: 100vh - $header-height;
      width: 100%;
      left: 0;
      top: 0;
      position: fixed;
      //overflow-y: scroll;
      z-index: 1;
    }

    .ui.icon.input.icon.search-input {
      input {
        background: transparent;
        border: 1px solid var(--text-secondary);
        transition: border .3s ease;
        border-radius: 20px;

        &:focus {
          background: transparent;
          border: 1px solid var(--text-color);
          border-radius: 20px;
          color: var(--text-color);
        }
      }

      & .icon {
        color: var(--text-color);
      }
    }
  }

  a,
  a:hover {
    color: inherit;
  }
}

.closeBtn {
  font-size: 30px;
  width: 20%;
  float: right;
  border-radius: 10px;
  margin-right: -30px;
  margin-top: -10px;

  img {
    width: 32px;
  }

  /* &::before {
    color: white;
    width: 100%;
    height: 100%;
    transform: rotate(135deg);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  } */
}

.menuText {
  font-size: 20px;
  float: left;
}

.ui.inverted.vertical.labeled.icon.ui.overlay.right.wide.visible.sidebar.menu {
  background: black !important;
}

.sidebarVisible {
  z-index: 9;
  //transition: ease-in-out 2s !important;
}

.sideLogo {
  width: 100px !important;
  margin-top: 10px !important;
  margin-bottom: 30px !important;
}