.bugBoxDesign {
  background: #bf20ab;
  position: fixed;
  right: 0;
  top: 54%;
  -webkit-transform: translate(-20px, 100%) rotate(
90deg
);
  transform: translate(-20px, 100%) rotate(
90deg
);
  padding: 7px;
  -webkit-transform-origin: right center;
  transform-origin: right center;
  border-radius: 4px;
}
.bugText{
  color: white;
    font-size: 16px;
    font-weight: 400;
    margin-left: 10px;
    margin-right: 10px;
}