@mixin mobile {
  @media (max-width: 900px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 901px) and (max-width: 1199px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin lg_desktop {
  @media (min-width: 1800px) {
    @content;
  }
}