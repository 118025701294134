@mixin mobile {
  @media (max-width: 900px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 901px) and (max-width: 1199px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin lg_desktop {
  @media (min-width: 1800px) {
    @content;
  }
}

.collections {
  background-image: url("/assets/artist/collection/48096308.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  width: 100%;
  height: 100%;
  padding: 150px 0px;
  text-align: center;
  box-shadow: 0 0 100px 50px #03011e inset;

  //margin: 100px 0;
  @include mobile {
    padding: 10px;

    .column {
      width: 100% !important;
    }
  }

  b {
    color: #59d6e6;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;
  }

  .container {
    max-width: 1440px !important;
    margin: auto;
  }

  h3 {
    padding: 20px;
    font-family: "Lexend";
    font-style: normal;
    font-weight: 600;
    font-size: 58px;
    line-height: 56px;
    //text-align: center;
    letter-spacing: -0.02em;
    color: #fff0fa;

    span {
      background: -webkit-linear-gradient(358deg, #e659d6, #8877ea, #86e2ee);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    @include mobile {
      padding: 0;
      font-size: 28px;
      line-height: 24px;
    }
  }

  .featured_nft_image {
    width: 200px;
  }

  .swiper-container {
    padding-top: 55px;
    padding-bottom: 55px;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }

  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 45%;
    width: 40px !important;
    height: 40px !important;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.08);
    border-radius: 40px;
    color: #fff !important;

    &::after {
      padding: 15px;
      font-size: 15px;
      font-weight: bolder;
    }
  }

  .collectionbg {
    //background-image: url("/assets/artist/collectionbg.png");
    //background-repeat: no-repeat;
    //background-size: contain;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    margin: auto;

    @include mobile {
      height: 250px;
      width: 100%;
    }

    .collection_image {
      width: 100%;
      max-width: 600px !important;
      height: 340px !important;
      border-radius: 10px 10px 0 0;
      object-fit: cover;

      @include mobile {
        height: 100% !important;
        width: 100%;
      }
    }
  }

  .collection_button {
    width: auto;
    margin-top: 0;
    text-align: center;
    padding: 14px 20px;
    margin-top: 0;
    font-size: 18px;
    font-weight: 600;

    color: #fff;
    background: rgba(89, 214, 230, 0.2);
    border: 1.66247px solid #59D6E6 !important;
    border-radius: 8px;

    &:hover {
      color: #fff;
      background: #59D6E6;
      border: 1.66247px solid #59D6E6 !important;
    }
  }

  .collection {
    max-width: 600px;
    min-width: 600px;
    width: max-content !important;
    height: max-content !important;
    min-height: 340px !important;
    object-fit: cover;

    background: #15142C !important;
    border: 1px solid #262636;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    border-radius: 10px;

    margin-bottom: 40px !important;

    @include mobile {
      height: 519px;
      width: 100%;
      min-width: 100%;
      max-width: 100%;
    }

    .collection_header {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }

    .collection_details {

      text-align: center;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.01em;
      font-feature-settings: 'cv05'on, 'cv11'on;

      padding: 20px;

      .project_image img {
        max-width: 60px;
        border-radius: 50px;
      }

      p {
        color: #fff;
        font-size: 21px;
        font-weight: 700;
        color: #fff;
        margin-bottom: 10px;

        span {
          display: block;
          color: rgba(246, 245, 255, 0.6);
          font-weight: 400 !important;
          font-size: 14px !important;
          line-height: 27px !important;
          text-align: center !important;
        }
      }

      .nft_button {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #887cec;
      }
    }
  }
}